import { useContext, BaseSyntheticEvent, FC, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import { AuthContext } from '../../store/AuthContext';
import { getFullName } from '../../utils/utils';
import { useUser } from '../../hooks';
import { routes } from '../../models';

import PageLogo from '../../assets/images/page_logo.webp';
import avatar from '../../assets/images/avatar.svg';

import styles from './Header.module.scss';

const ProfileLink: FC<{
  fullName: string;
  imageIndex: number;
}> = memo(({ fullName, imageIndex }) => {
  const { getAvatar } = useUser();
  return (
    <div className={`d-flex align-items-center p-1 ${styles.profileLink}`}>
      <div className="d-flex">
        <img
          className={imageIndex ? 'rounded-circle' : ''}
          src={imageIndex !== 0 ? getAvatar(imageIndex) : avatar}
          width={40}
          height={40}
          alt="Avatar"
        />
      </div>
      <div className="d-flex align-items-center px-1">
        <p className="fw-semibold mb-0">{fullName}</p>
      </div>
      <div className="d-flex pe-2">
        <i className="icon-chevron-down text-primary" />
      </div>
    </div>
  );
});

export const Header: FC = memo(() => {
  const { isLoggedIn, session, imageIndex } = useContext(AuthContext);
  const navigate = useNavigate();

  const onLogoutHandler = (event: BaseSyntheticEvent) => {
    if (isLoggedIn) {
      window.location.replace(
        `${window.REACT_APP_API_URL}/logout?redirectUri=${window.location.origin}`,
      );
    } else {
      navigate(routes.login);
    }
    event.preventDefault();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white custom-header fixed-top py-3">
      <div className="container">
        <Link
          to={routes.home}
          onClick={() => window.scrollTo(0, 0)}
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <img src={PageLogo} alt="DotGov Sign" className="img-fluid" />
        </Link>

        <ul className="nav nav-pills">
          <li className="nav-item">
            <h6>
              <Link
                to={routes.documentSign}
                className="nav-link text-main semi-bold fst-normal"
              >
                E-sign a Document
              </Link>
            </h6>
          </li>
          <li className="nav-item">
            <h6>
              <Link
                to={routes.documentVerify}
                className="nav-link text-main semi-bold fst-normal"
              >
                Verify E-Signature
              </Link>
            </h6>
          </li>
          {isLoggedIn ? (
            <li className="nav-item">
              <div
                className={`${styles.menu} justify-content-end align-items-center`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="open-sans-medium">
                    <Dropdown align="end">
                      <Dropdown.Toggle
                        className={`no-hover profile-link-dropdown p-0 ${styles.dropdownToggle}`}
                        variant="success"
                      >
                        <ProfileLink
                          imageIndex={imageIndex}
                          fullName={getFullName(session)}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href={routes.profileInfo}>
                          <div className="d-flex align-items-center p-1">
                            <div className={`d-flex ${styles.dropdownItem}`}>
                              <i
                                style={{ fontSize: '24px' }}
                                className="icon-user text-primary"
                              />
                            </div>
                            <div className="d-flex">Profile information</div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href={routes.profileHistory}>
                          <div className="d-flex align-items-center p-1">
                            <div className={`d-flex ${styles.dropdownItem}`}>
                              <i
                                style={{ fontSize: '20px' }}
                                className="icon-clock text-primary"
                              />
                            </div>
                            <div className="d-flex">Signature history</div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`${window.REACT_APP_AUTH_URL}/settings/profile`}
                          target="new_blank"
                        >
                          <div className="d-flex align-items-center p-1">
                            <div className={`d-flex ${styles.dropdownItem}`}>
                              <i
                                style={{ fontSize: '15px' }}
                                className="icon-zampass-account text-primary"
                              />
                            </div>
                            <div className="d-flex">
                              Manage my ZamPass Account
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={onLogoutHandler}>
                          <div className="d-flex align-items-center p-1">
                            <div className={`d-flex ${styles.dropdownItem}`}>
                              <i
                                style={{ fontSize: '23px' }}
                                className="icon-logout text-primary"
                              />
                            </div>
                            <div className="d-flex">Logout</div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </li>
          ) : null}
          {!isLoggedIn && (
            <li className="nav-item d-flex align-items-center">
              <h6 className={isLoggedIn ? styles.logout : ''}>
                <Link
                  to={''}
                  className="nav-link text-main semi-bold fst-normal"
                  onClick={onLogoutHandler}
                >
                  Login
                </Link>
              </h6>
            </li>
          )}
          {/* <li className="nav-item d-flex align-items-center"> */}
          {/*   <SwitchThemeCheckbox /> */}
          {/* </li> */}
        </ul>
      </div>
    </nav>
  );
});
