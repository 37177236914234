import React from 'react';
import { ButtonSetting } from '../../interfaces';

export const Buttons = ({ buttons }: { buttons: ButtonSetting[] }) => {
  return (
    <>
      {buttons?.map((btn, index) => {
        return (
          <div className={btn.parentClass || 'col-6'} key={index}>
            <button
              className={`btn ${btn.class} flex-fill w-100 p-2`}
              onClick={btn.onClick}
              disabled={btn.disabled}
            >
              <i className={`${btn.iconClass} custom-fs-2 me-2`}></i>
              {btn.label}
            </button>
          </div>
        );
      })}
    </>
  );
};
