import React from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { THEMES } from './constants';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './store/AuthContext';
import 'swiper/css/bundle';
import 'bootstrap/dist/js/bootstrap.bundle';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
        <ThemeSwitcherProvider defaultTheme="light" themeMap={THEMES}>
          <App />
        </ThemeSwitcherProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
