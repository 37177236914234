import Axios from '../utils/axios-config';
import { Session } from '../interfaces/user-info';

export const useUser = () => {
  const getUserInfo = (): Promise<{ data: Session }> => {
    return Axios.get(`${window.REACT_APP_API_URL}/userinfo`);
  };

  const checkAvatar = () => {
    return Axios.get('/self-service/avatar', {
      withCredentials: true,
      baseURL: window.REACT_APP_AUTH_URL,
    });
  };

  const getAvatar = (index: number) => {
    return `${window.REACT_APP_AUTH_URL}/self-service/avatar?index=${index}`;
  };

  return {
    getUserInfo,
    checkAvatar,
    getAvatar,
  };
};
