import styles from './Login.module.scss';
import { FOOTER_TEXT } from '../../constants';
import { LoginForm } from '../../components';

export const Login = () => {
  const handleSubmit = () => {
    window.location.replace(
      `${window.REACT_APP_API_URL}/login?redirectUri=${window.location.origin}`,
    );
  };

  return (
    <div className={`${styles.loginWrapper} container-fluid min-vh-100`}>
      <div className="row justify-content-center align-content-center min-vh-95 flex-column">
        <LoginForm
          handleSubmit={handleSubmit}
          buttonLabel={'Log In with DotGovPass'}
        />
      </div>
      <footer className="d-flex justify-content-center text-white p5-400">
        {FOOTER_TEXT}
      </footer>
    </div>
  );
};
