import { DocumentStatus } from './enums';

export const THEMES = {
  light: '/themes/light.css',
  dark: '/themes/dark.css',
};

export const ACCEPTABLE_SIGNATURE_IMAGE_FORMAT = 'image/jpeg,image/png';

export const DATE_FORMAT = 'MMM DD YYYY HH:mm:ss';
export const LABEL_MAP = new Map<DocumentStatus, string>([
  [DocumentStatus.SIGNED, 'Signed'],
  [DocumentStatus.PENDING, 'Pending Signature'],
  [DocumentStatus.CANCELED, 'Cancelled'],
]);

export const FOOTER_TEXT =
  'DotGov Sign - Government Digital Signature Service. 2024, All Rights Reserved.';

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please try again later';
