import axios from 'axios';

const Axios = axios.create({
  baseURL: window.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
  withCredentials: true,
});

export default Axios;
