export const routes = {
  login: '/login',
  home: '/',
  documentSign: '/document/sign',
  documentVerify: '/document/verify',
  wildcard: '/*',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  profileInfo: '/profile/info',
  profileHistory: '/profile/history',
};
