import React, { useMemo } from 'react';
import { DocumentType } from '../../enums';
import styles from './Stepper.module.scss';

export const Stepper = ({
  step,
  section,
}: {
  step: number;
  section: string;
}) => {
  const stepsMap = new Map<DocumentType, { id: string; title: string }[]>([
    [
      DocumentType.SIGN,
      [
        {
          id: 'first',
          title: 'Select a document',
        },
        {
          id: 'second',
          title: 'Specify the position of the e-signature',
        },
        {
          id: 'completed',
          title: 'Document signing',
        },
      ],
    ],
    [
      DocumentType.VERIFY,
      [
        {
          id: 'first',
          title: 'Select a document',
        },
        {
          id: 'completed',
          title: 'Document verification',
        },
      ],
    ],
  ]);

  const steps = stepsMap.get(section as DocumentType);
  const memoizedSteps = useMemo(
    () =>
      steps?.map((item, index) => {
        const active =
          step === index ? 'active shadow p-sm-1 bg-white text-primary' : '';
        const iconClass =
          step >= index ? 'btn-primary' : 'btn-outline-primary border-2';
        const done =
          step > index ? 'done shadow p-sm-1 bg-white text-primary' : '';

        return (
          <div
            className={`col-12 col-sm-3 my-1 d-flex align-items-center ${active} ${done}`}
            key={item.id}
          >
            <div className="d-flex flex-column align-items-center me-3">
              <div
                className={`${
                  styles.stepIcon
                } rounded-circle fw-bold fs-6 ${iconClass} ${
                  active ? '' : 'disabled'
                } text-center`}
              >
                {index + 1}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <p className="p5-400 text-dark m-0">{item.title}</p>
            </div>
          </div>
        );
      }),
    [steps, step],
  );

  return <div className={`${styles.stepper} row px-2`}>{memoizedSteps}</div>;
};
