import React from 'react';
import { Link } from 'react-router-dom';

import styles from './LoginForm.module.scss';
import LogoIcon from '../../assets/images/logo.svg';
import HomeIcon from '../../assets/images/icons/home.svg';
import { routes } from '../../models';

interface LoginFormProps {
  handleSubmit: () => void;
  buttonLabel: string;
}

export const LoginForm = ({ handleSubmit, buttonLabel }: LoginFormProps) => {
  return (
    <div className={styles.loginEnter}>
      <div className="d-flex justify-content-end mb-2">
        <Link to={routes.home} className="text-decoration-none">
          <img src={HomeIcon} className="img-fluid" alt="Home" />
        </Link>
      </div>
      <div
        className={`${styles.loginBackground} rounded px-4 h-100 d-flex flex-column align-items-center`}
      >
        <Link to={routes.home} className="d-flex justify-content-center my-4">
          <img src={LogoIcon} className="img-fluid mt-5" alt="Logo" />
        </Link>
        <div className="w-100 h-100 d-flex justify-content-center flex-column px-3">
          <button
            className="btn btn-lg btn-primary text-white"
            onClick={handleSubmit}
          >
            <p className="p1-600 m-0">{buttonLabel}</p>
          </button>
        </div>
      </div>
    </div>
  );
};
