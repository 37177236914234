export enum HistoryKey {
  STATUS = 'statuses',
  SORT_BY_NAME = 'sortByName',
  SORT_BY_CREATED = 'sortByCreated',
  SORT_BY_SIGNED = 'sortBySigned',
  SELECT = 'select',
}

export enum TooltipDirection {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  AUTO = 'auto',
}
