import React from 'react';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './Slider.module.scss';

// Icons for slider
import step1 from '../../assets/images/icons/step1.png';
import step2 from '../../assets/images/icons/step2.png';
import step3 from '../../assets/images/icons/step3.png';
import step4 from '../../assets/images/icons/step4.png';
import step5 from '../../assets/images/icons/step5.png';

interface SwiperItem {
  title: string;
  text: string;
  imagePath: string;
}

export const Slider = () => {
  const swiperItems: SwiperItem[] = [
    {
      title: 'Step 1',
      text: 'Check out how to sign a document. Log into your account, and click the "E-sign DocumentView" button',
      imagePath: step1 ?? '',
    },
    {
      title: 'Step 2',
      text: 'Upload your document. Press "Select a file" or drag the file to the upload area.',
      imagePath: step2 ?? '',
    },
    {
      title: 'Step 3',
      text: 'Place your signature. Drag the "Signature" from the right to your document. Then click "Sign".',
      imagePath: step3 ?? '',
    },
    {
      title: 'Step 4',
      text: 'Check your phone. Confirm your signature in the mobile application.',
      imagePath: step4 ?? '',
    },
    {
      title: 'Step 5',
      text: "Congrats, you've just signed a document. You can now share or download it.",
      imagePath: step5 ?? '',
    },
  ];
  return (
    <div className={`${styles.slide} container`}>
      <Swiper
        loop
        navigation
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation, A11y]}
        onSwiper={(swiper) => console.warn(swiper)}
        onSlideChange={() => console.warn('slide change')}
        className="pb-4"
      >
        {swiperItems.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="row mb-5 px-md-4">
              <div className="col-md-5 col-12">
                <img
                  className="img-fluid"
                  src={item.imagePath}
                  alt="Tutorial images"
                />
              </div>
              <div className="col-md-7 col-12 d-flex justify-content-center flex-column">
                <h3 className="pb-2 font-weight-500">{item.title}</h3>
                <h3 className="font-weight-500">{item.text}</h3>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
