import { StatusStep } from '../../enums';
import { ButtonSetting } from '../../interfaces';
import { StatusConfig } from '../../interfaces/status-config';
import { DocumentPlaceholder } from '../DocumentPlaceholder/DocumentPlaceholder';
import { Status } from '../Status/Status';
import SuccessSignIcon from '../../assets/images/icons/success-sign-icon.svg';
import FailureSignIcon from '../../assets/images/icons/failure-sign-icon.svg';
import { Spinner } from '../Spinner/Spinner';

export const titleMap = new Map<StatusStep, string>([
  [StatusStep.FIRST, 'Upload the e-signed document to be verified'],
  [StatusStep.VERIFY_UPLOAD, 'Upload the e-signed document to be verified'],
  [StatusStep.UPLOADED, 'Preview the details of the e-signed document'],
  [StatusStep.ERROR, 'Preview the details of the e-signed document'],
  [StatusStep.SUCCESS, 'Preview the details of the e-signed document'],
]);

const VERIFY_ERROR = {
  DESCRIPTION:
    'The uploaded document has no valid signatures. You can sign it by accessing the "E-Sign a Document" page.',
  TITLE: 'No signatures found',
};

export const buttonsMap = new Map<
  StatusStep,
  ({
    handleBackBtn,
    onChangeStep,
    cancelDocSign,
  }: {
    handleBackBtn: () => void;
    onChangeStep: (status: StatusStep, step: number) => void;
    cancelDocSign: () => void;
  }) => ButtonSetting[]
>([
  [
    StatusStep.FIRST,
    ({ handleBackBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
      ];
    },
  ],
  [
    StatusStep.VERIFY_UPLOAD,
    ({ handleBackBtn, cancelDocSign }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          label: 'Cancel',
          class: 'btn-primary',
          onClick: cancelDocSign,
        },
      ];
    },
  ],
  [
    StatusStep.SUCCESS,
    ({ onChangeStep }) => {
      return [
        {
          label: 'Upload New Document',
          class: 'btn-primary',
          onClick: () => onChangeStep(StatusStep.FIRST, 0),
        },
      ];
    },
  ],
  [
    StatusStep.ERROR,
    ({ onChangeStep }) => {
      return [
        {
          label: 'Upload New Document',
          class: 'btn-primary',
          onClick: () => onChangeStep(StatusStep.FIRST, 0),
        },
      ];
    },
  ],
]);

export const statusMap = new Map<StatusStep, StatusConfig>([
  [StatusStep.UPLOADED, { Component: DocumentPlaceholder }],
  [StatusStep.SIGN, { Component: DocumentPlaceholder }],
  [
    StatusStep.SUCCESS,
    {
      Component: Status,
      img: {
        url: SuccessSignIcon,
        alt: 'SuccessSignIcon',
      },
      title: 'The file was successfully signed',
      showDetails: true,
    },
  ],
  [
    StatusStep.ERROR,
    {
      Component: Status,
      img: {
        url: FailureSignIcon,
        alt: 'FailureSignIcon',
      },
      title: VERIFY_ERROR.TITLE,
      description: VERIFY_ERROR.DESCRIPTION,
    },
  ],
  [
    StatusStep.VERIFY_UPLOAD,
    {
      Component: Status,
      SpinnerComponent: Spinner,
      title: 'Uploading Document...',
      description: 'It may take some time.',
    },
  ],
  [
    StatusStep.SUCCESS,
    {
      Component: Status,
      showDetails: true,
    },
  ],
]);
