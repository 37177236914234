import { StatusStep } from '../../enums';
import { ButtonSetting } from '../../interfaces';
import { DocumentPlaceholder } from '../DocumentPlaceholder/DocumentPlaceholder';
import { Status } from '../Status/Status';
import SuccessSignIcon from '../../assets/images/icons/success-sign-icon.svg';
import FailureSignIcon from '../../assets/images/icons/failure-sign-icon.svg';

export const SIGNATURE_LOCATION = 'ZamSign';

export const titleMap = new Map<StatusStep, string>([
  [StatusStep.FIRST, 'Upload the .PDF electronic document for signing'],
  [
    StatusStep.UPLOADED,
    'Specify the position of the e-signature display on the document',
  ],
  [
    StatusStep.SIGN,
    'Specify the position of the e-signature display on the document',
  ],
  [StatusStep.PROGRESS, 'Open MobileID and sign the document'],
  [StatusStep.SUCCESS, 'Open MobileID and sign the document'],
  [StatusStep.SUCCESS, 'Open MobileID and sign the document'],
]);

export const getPlaceholderComp = () => ({ Component: DocumentPlaceholder });
export const getSuccessText = () => ({
  Component: Status,
  img: {
    url: SuccessSignIcon,
    alt: 'SuccessSignIcon',
  },
  title: 'The file was successfully signed',
  showDetails: true,
});

export const getErrorText = (title?: string, description?: string) => ({
  Component: Status,
  img: {
    url: FailureSignIcon,
    alt: 'FailureSignIcon',
  },
  title: title || 'Signature Canceled',
  description:
    description ||
    'It seems you cancelled the signature or did not confirm it on the phone. You can try again.',
});

export const buttonsMap = new Map<
  StatusStep,
  ({
    handleBackBtn,
    handleSignBtn,
    resetSteps,
    handleDownload,
    handleCancelBtn,
  }: {
    handleBackBtn: () => void;
    handleSignBtn: () => void;
    resetSteps: () => void;
    handleDownload: () => void;
    handleCancelBtn: () => void;
  }) => ButtonSetting[]
>([
  [
    StatusStep.FIRST,
    ({ handleBackBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
      ];
    },
  ],
  [
    StatusStep.UPLOADED,
    ({ handleBackBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          disabled: true,
          label: 'Sign',
          class: 'btn-primary',
          onClick: () => console.log('No action'),
        },
      ];
    },
  ],
  [
    StatusStep.SIGN,
    ({ handleBackBtn, handleSignBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          disabled: false,
          label: 'Sign',
          class: 'btn-primary',
          onClick: handleSignBtn,
        },
      ];
    },
  ],
  [
    StatusStep.PROGRESS,
    ({ handleBackBtn, handleCancelBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          label: 'Cancel',
          class: 'btn-primary',
          onClick: handleCancelBtn,
        },
      ];
    },
  ],
  [
    StatusStep.SUCCESS,
    ({ handleDownload, resetSteps }) => {
      return [
        {
          label: 'Download',
          iconClass: 'icon-download-key',
          class: 'btn-outline-primary',
          onClick: handleDownload,
        },
        {
          label: 'Upload New Doc',
          class: 'btn-primary',
          onClick: resetSteps,
        },
      ];
    },
  ],
  [
    StatusStep.ERROR,
    ({ resetSteps }) => {
      return [
        {
          label: 'Cancel',
          class: 'btn-outline-primary',
          onClick: resetSteps,
        },
        {
          label: 'Try Again',
          class: 'btn-primary',
          onClick: resetSteps,
        },
      ];
    },
  ],
]);
