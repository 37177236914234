import { FC, memo } from 'react';

import styles from './Delimiter.module.scss';

interface DelimiterProps {
  className?: string;
  height?: string;
}

export const Delimiter: FC<DelimiterProps> = memo(
  ({ className = 'my-2', height = '1px' }) => (
    <div
      style={{ height }}
      className={`${styles.delimiter} ${className} w-100`}
    ></div>
  ),
);
