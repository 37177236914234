import React, { useState, useContext, Suspense, useEffect } from 'react';
import { toast } from 'react-toastify';
import './App.scss';
import { Navigation } from './navigation/Navigation';
import { ToastContainer } from 'react-toastify';
import { useUser } from './hooks';
import { AuthContext } from './store/AuthContext';
import { AxiosError } from 'axios';
import { DEFAULT_ERROR_MESSAGE } from './constants';

export const App = () => {
  const [session, setSession] = useState<boolean>(false);
  const context = useContext(AuthContext);
  const { getUserInfo } = useUser();

  useEffect(() => {
    getUserInfo()
      .then(({ data }) => {
        if (data?.isAuthenticated) {
          context.login(data);
        } else {
          context.logout();
        }
      })
      .catch((error: AxiosError) => {
        toast.error(error.message || DEFAULT_ERROR_MESSAGE);
        context.logout();
      })
      .finally(() => {
        setSession(true);
      });
  }, []);
  return (
    <Suspense fallback="Loading application layout, wait please">
      {session && <Navigation />}
      <ToastContainer position="bottom-right" />
    </Suspense>
  );
};
