import React, { useState } from 'react';

import { Session } from '../interfaces/user-info';
import { useUser } from '../hooks';

const DEFAULT_SESSION = {
  isAuthenticated: false,
};

interface AuthContextProps {
  isLoggedIn: boolean;
  imageIndex: number;
  session?: Session;
  login: (session: Session) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  isLoggedIn: false,
  imageIndex: 0,
  session: DEFAULT_SESSION,
  login: () => ({}),
  logout: () => ({}),
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [session, setSession] = useState<Session>(DEFAULT_SESSION);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const { checkAvatar } = useUser();

  const loginHandler = (userSession: Session) => {
    setSession(userSession);
    checkAvatar().then(({ data }) => {
      setImageIndex(data ? 1 : 0);
    });
  };

  const logoutHandler = () => {
    setSession({ isAuthenticated: false });
  };

  const updateImage = (index: number) => setImageIndex(index);

  const contextValue = {
    isLoggedIn: session.isAuthenticated,
    imageIndex,
    session: session,
    login: loginHandler,
    logout: logoutHandler,
    updateImage,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
