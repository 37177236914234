import React, { useEffect, useState } from 'react';
import { DocumentView } from '../DocumentView/DocumentView';
import { ButtonSetting, ProfileHistory } from '../../interfaces';
import { DocumentStatus } from '../../enums';
import { Status } from '../Status/Status';
import styles from './HistoryDetails.module.scss';
import { FileType } from '../../enums/file-type';
import { useDocument } from '../../hooks';

interface HistoryDetailsProps {
  documentHasChanged: () => void;
  historyItem: ProfileHistory;
}

export const HistoryDetails = ({
  documentHasChanged,
  historyItem,
}: HistoryDetailsProps) => {
  const [file, setFile] = useState<FileType>(null);
  const [hasPendingStatus, setHasPendingStatus] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [otp, setOtp] = useState('');
  const { cancelDocumentSign, downloadFile, getDocumentById, renewOtpCode } =
    useDocument();

  const cancelBtn: ButtonSetting = {
    label: 'Cancel Singing',
    iconClass: 'icon-arrow-hook',
    class: 'btn-light text-primary',
    parentClass: 'col-12 mt-4',
    onClick: () => {
      cancelDocumentSign(historyItem.documentId)
        .then(() => {
          setHasPendingStatus(false);
          historyItem.status = DocumentStatus.CANCELED;
          documentHasChanged();
          setOtp('');
        })
        .catch((error) => console.error(error));
    },
  };

  const resendBtn: ButtonSetting = {
    label: 'Resend New OTP',
    iconClass: 'icon-refresh',
    class: 'btn-outline-primary',
    parentClass: 'col-12',
    onClick: () => {
      renewOtpCode(historyItem.documentId)
        .then(({ data }) => setOtp(data))
        .catch((error) => console.error(error));
    },
  };

  const downloadBtn: ButtonSetting = {
    label: 'Download PDF',
    iconClass: 'icon-download-key',
    class: 'btn-primary',
    parentClass: 'col-12',
    onClick: () => {
      downloadFile(historyItem.documentId, historyItem.fileName);
    },
  };

  const config = {
    showDetails: true,
    btn: hasPendingStatus ? [resendBtn, downloadBtn, cancelBtn] : [downloadBtn],
    otp: otp || '',
  };

  useEffect(() => {
    if (historyItem.documentId) {
      setLoadingFile(true);
      getDocumentById(historyItem.documentId)
        .then(({ data }: { data: FileType }) => {
          setFile(data);
          setLoadingFile(false);
        })
        .catch(() => setLoadingFile(false));
      setHasPendingStatus(historyItem.status === DocumentStatus.PENDING);
    }

    return () => {
      setLoadingFile(false);
      setFile(null);
      setOtp('');
    };
  }, [historyItem.documentId]);

  return (
    <div
      className="modal fade"
      id="historyDetails"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className={`${styles.historyDetails} modal-content`}>
          <div className="modal-header border-bottom-0">
            <h6 className="modal-title">Document Information</h6>
            <button
              type="button"
              className={`${styles.closeBtn} border-0 p-0`}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon-close text-primary" />
            </button>
          </div>
          <div className="modal-body mb-4">
            <div className="row">
              <div
                className={`${styles.historyPdf} col-md-7 col-sm-6 position-relative`}
              >
                {file ? (
                  <DocumentView file={file} />
                ) : (
                  <div className="min-vh-50 d-flex justify-content-center bg-white shadow bg-white">
                    <p className="p2-600 mt-5">
                      {loadingFile ? 'Loading' : 'No file'}
                    </p>
                  </div>
                )}
              </div>
              <div
                className={`${
                  hasPendingStatus
                    ? styles.pendingStatus
                    : styles.canceledStatus
                } col-md-5 col-sm-6`}
              >
                <Status
                  config={config}
                  details={{
                    ...historyItem,
                    signed: !!historyItem.signed,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
