import React, { BaseSyntheticEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getDetails, uploadFile } from '../../utils';
import { DocumentType, StatusStep } from '../../enums';
import { ButtonSetting, Details, SignatureDetails } from '../../interfaces';

import { FileType } from '../../enums/file-type';
import { buttonsMap, statusMap, titleMap } from './constants';
import { Wizard } from '../Wizard/Wizard';
import { useDocument } from '../../hooks';

export const Verify = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [viewDocStep, setViewDocStep] = useState<StatusStep>(StatusStep.FIRST);
  const [file, setFile] = useState<FileType>(null);
  const [details, setDetails] = useState<Details>({} as Details);
  const controllerRef = useRef<AbortController | null>();
  const { verifySignature } = useDocument();

  const handleBackBtn = () => {
    if (!step) {
      navigate(-1);
    } else {
      setStep(step - 1);
      setViewDocStep(StatusStep.FIRST);
    }
  };

  const onChangeStep = (value: StatusStep, stepNumber = 1) => {
    setViewDocStep(value);
    setStep(stepNumber);
  };

  const cancelDocSign = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    onChangeStep(StatusStep.FIRST, 0);
  };

  const { Component, ...configStatus } = statusMap.get(viewDocStep) || {};

  const onChange = (e: BaseSyntheticEvent): void => {
    onChangeStep(StatusStep.VERIFY_UPLOAD);

    uploadFile(e, (newFile: FileType, uploadedFile) => {
      setFile(newFile);

      controllerRef.current = new AbortController();
      verifySignature(
        { file: uploadedFile },
        { signal: controllerRef.current?.signal },
      )
        .then(({ data }) => {
          setDetails(getDetails(data, uploadedFile.name));
          onChangeStep(StatusStep.SUCCESS);
          controllerRef.current = null;
        })
        .catch((error) => {
          if (error.code !== 'ERR_CANCELED') {
            onChangeStep(StatusStep.ERROR);
          }
          controllerRef.current = null;
        });
    });
  };

  return (
    <Wizard
      title="Verify the document"
      step={step}
      section={DocumentType.VERIFY}
      subtitle={titleMap.get(viewDocStep) as string}
      component={() => {
        return (
          Component && <Component config={configStatus} details={details} />
        );
      }}
      buttons={
        (buttonsMap.get(viewDocStep) as ({}) => ButtonSetting[])({
          handleBackBtn,
          onChangeStep,
          cancelDocSign,
        }) as ButtonSetting[]
      }
      viewDocStep={viewDocStep}
      onChange={onChange}
      file={file}
      onLoadError={() => setFile(null)}
    />
  );
};
