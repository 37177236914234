import React from 'react';
import { Link } from 'react-router-dom';
import SignIcon from '../../assets/images/icons/sign.svg';
import VerifyIcon from '../../assets/images/icons/verify.svg';

import { Slider } from '../../components';
import './Home.scss';
import { routes } from '../../models';

export const Home = () => {
  const navigateLinks = [
    {
      imagePath: SignIcon,
      altText: 'Sign logo',
      path: routes.documentSign,
      cssClass: 'text-white nav-link btn btn-primary',
      label: 'Sign the document',
    },
    {
      imagePath: VerifyIcon,
      altText: 'Verify logo',
      path: routes.documentVerify,
      cssClass: 'text-white nav-link btn btn-primary',
      label: 'Verify signature',
    },
  ];

  return (
    <>
      <section className="bg d-flex align-items-center pt-5 mt-5">
        <div className="container d-flex align-items-center">
          <div className="d-flex text-white flex-column flex-lg-row align-items-center row mt-md-0 mt-sm-5 mb-sm-3">
            <div className="col-6">
              <h1>Government Digital Signature Service</h1>
              <h4 className="pt-4">
                E-Sign and verify documents online at a glance. E-Government at
                your service
              </h4>
            </div>

            <div className="col-6 d-flex flex-column align-items-end align-items-sm-center">
              {navigateLinks.map((link, index) => (
                <div className="py-2 navigation-section" key={index}>
                  <Link
                    to={link.path}
                    className="d-flex align-items-center btn btn-primary"
                    key={index}
                  >
                    <img src={link.imagePath} alt={link.altText} />
                    <span>{link.label}</span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section>
        <Slider />
      </section>
    </>
  );
};
