import React from 'react';

interface UploadSignatureLabelProps {
  file: {
    name: string;
  };
  handleRemove: () => void;
  display: string;
}

export const UploadSignatureLabel = ({
  file,
  handleRemove,
  display,
}: UploadSignatureLabelProps) => {
  return (
    <div className={`${display} row bg-grey-2 p-0 m-0 py-2`}>
      <div className="col-2"></div>
      <div className="col-8 d-flex justify-content-center">
        <label>{file?.name}</label>
      </div>
      <div className="col-2 d-flex justify-content-center">
        <span className="text-error cursor-pointer" onClick={handleRemove}>
          x
        </span>
      </div>
    </div>
  );
};
