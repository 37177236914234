import { BaseSyntheticEvent } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { DocumentStatus } from '../enums';
import { Details, FormField, Props } from '../interfaces';
import { FileType } from '../enums/file-type';
import { Session } from '../interfaces/user-info';
import { DEFAULT_ERROR_MESSAGE } from '../constants';
import { VerifyDocumentResponse } from '../interfaces/api-response';

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getDetails(
  data: VerifyDocumentResponse,
  fileName: string,
): Details {
  return {
    fileName,
    status: data.isValid ? DocumentStatus.SIGNED : DocumentStatus.CANCELED,
    signatures: data.results.map((item) => {
      return {
        signatory: item.signer,
        timeStamp: item.timeStamp,
        id: item.id,
        issuer: item.issuer,
      };
    }),
  };
}

export function getBase64StringFromDataURL(dataURL: string) {
  return dataURL?.replace('data:', '')?.replace(/^.+,/, '');
}

export function getBase64Image(img: HTMLImageElement) {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img, 0, 0);
  const dataURL = canvas.toDataURL('image/png');

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

export const uploadFile = (
  e: BaseSyntheticEvent,
  successFn: (file: FileType, uploadedFile: File) => void,
) => {
  const reader = new FileReader();
  const uploadedFile = e.target.files[0];
  reader.readAsDataURL(uploadedFile);
  reader.onload = () => successFn(reader.result, uploadedFile);
  reader.onerror = (error) => {
    console.error('Error: ', error);
  };
};

export const getFullName = (session?: Session) => {
  return `${session?.claims?.firstName} ${session?.claims?.lastName}`;
};

export const handleError = (error: AxiosError | any) => {
  toast.error(
    error?.response?.data?.message ||
      (error?.response?.data as string) ||
      DEFAULT_ERROR_MESSAGE,
  );
};

export const validate = (field: FormField, values: Props) => (value: string) =>
  field?.rules?.find((rule) => rule.validator(value, values))?.message;

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function =>
  typeof value === 'function';

export function runIfFn<T, U>(
  valueOrFn: T | ((...fnArgs: U[]) => T),
  ...args: U[]
): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunction = () => {};

export const dispatchInputChangeEvent = (
  ref: HTMLElement,
  value = '',
  type = 'HTMLInputElement' as unknown,
): void => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window?.[type as number]?.['prototype' as any],
    'value',
  )?.set;
  nativeInputValueSetter?.call(ref, value);
  ref?.dispatchEvent(new Event('change', { bubbles: true }));
};

export const toJSONLocal = (date: Date) => {
  const local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 19).replace('T', ' ');
};
