import {
  BaseSyntheticEvent,
  FC,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useField, useFormikContext } from 'formik';

import { dispatchInputChangeEvent, emptyFunction } from '../../utils/utils';
import { Props } from '../../interfaces';

import styles from './Values.module.scss';

interface ValuesProps {
  name: string;
  placeholder: string;
  value: string;
  onChange: (values: string | string[]) => void;
  additionalInformation?: {
    title: string;
    text: string;
  };
}

export const Values: FC<ValuesProps> = memo(
  ({ value = [], name, onChange, placeholder, additionalInformation }) => {
    const [stringValue, setStringValue] = useState<string>('');
    const [_, __, helpers] = useField(name);
    const inputRef = useRef<HTMLInputElement>(null);
    const { values } = useFormikContext<Props>();

    const currentValue = useMemo(
      () => (value.length ? (value as string).split(',') : []),
      [value],
    );

    const onChangeHandler = useCallback((event: BaseSyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setStringValue(event.target.value);
    }, []);

    const onRemoveHandler = useCallback(
      (index: number) => () => {
        dispatchInputChangeEvent(inputRef.current as HTMLElement);
        const filterValues = currentValue.filter((item, i) => i !== index);
        const arrayValue = filterValues.length > 0 ? filterValues : [];
        const newValue = arrayValue ? arrayValue.join(',') : arrayValue;
        helpers.setValue(newValue);
        onChange(newValue);
      },
      [currentValue],
    );

    const onAddHandler = useCallback(() => {
      if (stringValue.trim().length) {
        dispatchInputChangeEvent(inputRef.current as HTMLElement);
        const inputValue = stringValue
          ? [stringValue.trim()]
          : [stringValue.trim()];
        const arrayValue = [...currentValue, ...inputValue];
        const newValue = arrayValue.join(',');
        helpers.setValue(newValue);
        helpers.setError(undefined);
        onChange(newValue);
      }
      setStringValue('');
    }, [values, value, currentValue, stringValue]);

    return (
      <div>
        {additionalInformation && (
          <div>
            <h5>{additionalInformation.title}</h5>
            <p className="custom-fs-2-1">{additionalInformation.text}</p>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <input
            className="d-none"
            type="text"
            ref={inputRef}
            onChange={emptyFunction}
            value={[...currentValue]}
            name={name}
          />
          <input
            className="form-control"
            type="text"
            placeholder={placeholder}
            value={stringValue}
            onChange={onChangeHandler}
          />
          <button
            type="button"
            className="btn btn-outline-primary open-sans-medium d-flex align-items-center mx-2"
            onClick={onAddHandler}
          >
            <i className="icon-add me-2"></i>
            <span className={styles.addTag}>Add</span>
          </button>
        </div>
        <div className="mt-3">
          {currentValue.map((tag: string, index: number) => (
            <div key={`tag-${index}`} className={`${styles.tag} me-3 mb-3`}>
              <span>{tag}</span>
              <i
                onClick={onRemoveHandler(index)}
                className={`${styles.iconClose} cursor-pointer icon-close fs-4`}
              ></i>
            </div>
          ))}
        </div>
      </div>
    );
  },
);
