import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Wildcard, History, UserInfo, Sign, Verify } from '../components';

import { PrivacyPolicy, Profile, TermsAndConditions } from '../pages';
import { ProtectedRoute } from '../guard/ProtectedRoute';

const Layout = React.lazy(() =>
  import('../components/Layout/Layout').then((module) => ({
    default: module.Layout,
  })),
);
const Login = React.lazy(() =>
  import('../pages/Login/Login').then((module) => ({ default: module.Login })),
);
const Home = React.lazy(() =>
  import('../pages/Home/Home').then((module) => ({ default: module.Home })),
);

export const Navigation = () => {
  return useRoutes([
    {
      path: '/',
      element: (
        <Layout>
          <Home />
        </Layout>
      ),
    },
    {
      path: '/*',
      element: (
        <Layout>
          <Wildcard />
        </Layout>
      ),
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/document',
      children: [
        {
          path: 'sign',
          element: (
            <ProtectedRoute>
              <Layout>
                <Sign />
              </Layout>
            </ProtectedRoute>
          ),
        },
        {
          path: 'verify',
          element: (
            <Layout>
              <Verify />
            </Layout>
          ),
        },
      ],
    },
    {
      path: '/profile',
      children: [
        {
          path: 'info',
          element: (
            <ProtectedRoute>
              <Profile>
                <UserInfo />
              </Profile>
            </ProtectedRoute>
          ),
        },
        {
          path: 'history',
          element: (
            <ProtectedRoute>
              <Profile>
                <History />
              </Profile>
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/terms-and-conditions',
      element: (
        <Layout>
          <TermsAndConditions />
        </Layout>
      ),
    },
    {
      path: '/privacy-policy',
      element: (
        <Layout>
          <PrivacyPolicy />
        </Layout>
      ),
    },
  ]);
};
