import {
  BaseSyntheticEvent,
  FC,
  useContext,
  useEffect,
  useState,
  memo,
} from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { AxiosError } from 'axios';

import {
  HistoryFilters,
  HistoryResponse,
  ProfileHistory,
} from '../../interfaces';
import { DocumentStatus, HistoryKey, SortType } from '../../enums';
import { HistoryDetails } from '../HistoryDetails/HistoryDetails';
import { DATE_FORMAT, LABEL_MAP } from '../../constants';
import { useDocument } from '../../hooks';
import { AuthContext } from '../../store/AuthContext';
import { handleError } from '../../utils/utils';
import { Tooltip } from '../Tooltip/Tooltip';
import { TooltipDirection } from '../../enums/history-key';

import styles from './History.module.scss';

const MODAL_ID = '#historyDetails';

enum ActionIcons {
  download = 'icon-download-key',
  view = 'icon-eye',
  archive = 'icon-archive',
  delete = 'icon-delete',
}

const TOOLTIP_TEXT_MAP = new Map([
  [ActionIcons.download, 'Download document'],
  [ActionIcons.view, 'View document'],
  [ActionIcons.archive, 'Add to archive...'],
  [ActionIcons.delete, 'Delete document'],
]);

const DOCUMENT_STATUS_MAP = new Map([
  [
    DocumentStatus.SIGNED,
    <>
      <i
        className={`${styles.statusIcon} text-success icon-status-signed me-1 custom-fs-2 d-inline-block align-middle`}
      />
      <p className="text-success d-inline-block m-0 align-middle">
        {DocumentStatus.SIGNED}
      </p>
    </>,
  ],
  [
    DocumentStatus.PENDING,
    <>
      <i
        className={`${styles.statusIcon} text-warning icon-status-pending me-1 custom-fs-2 d-inline-block align-middle`}
      />
      <p className="text-warning d-inline-block m-0 align-middle">
        Pending Signature
      </p>
    </>,
  ],
  [
    DocumentStatus.CANCELED,
    <>
      <i
        className={`${styles.statusIcon} text-error icon-status-cancelled me-1 custom-fs-2 d-inline-block align-middle`}
      />
      <p className="text-error d-inline-block m-0 align-middle">
        {DocumentStatus.CANCELED}
      </p>
    </>,
  ],
]);

interface TooltipContentProps {
  text: string;
  iconClass: ActionIcons;
}

const TooltipContent: FC<TooltipContentProps> = memo(({ text, iconClass }) => {
  return (
    <div className="py-2 d-flex align-items-center custom-fs-3 align-middle">
      <i
        className={`${iconClass} text-white me-2 custom-fs-2 d-inline-block`}
      />
      <p className="align-middle d-inline-block mb-0">{text}</p>
    </div>
  );
});

export const History = () => {
  const { session } = useContext(AuthContext);
  const [filters, setFilters] = useState<HistoryFilters>({
    nationalId: '',
    count: 15,
    page: 0,
  });
  const [list, setList] = useState<ProfileHistory[]>([]);
  const [documentsCount, setDocumentsCount] = useState<number>(1);
  const [selectedItem, setSelectedItem] = useState<ProfileHistory>({
    created: '',
    documentId: '',
    fileName: '',
    signed: '',
    status: DocumentStatus.CANCELED,
  });
  const [activeSelectedAll, setActiveSelectedAll] = useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const {
    downloadFile,
    getHistory,
    deleteMultipleDocuments,
    archiveDocument,
    archiveMultipleDocuments,
  } = useDocument();

  const pages = Math.ceil(documentsCount / filters.count);
  const HEAD = [
    {
      label: 'Select all',
      showSortIcon: false,
      key: HistoryKey.SELECT,
    },
    {
      label: 'Document Name',
      showSortIcon: true,
      key: HistoryKey.SORT_BY_NAME,
    },
    {
      label: 'Status',
      showFilter: true,
      key: HistoryKey.STATUS,
    },
    {
      label: 'Date Created',
      showSortIcon: true,
      key: HistoryKey.SORT_BY_CREATED,
    },
    {
      label: 'Date Signed',
      showSortIcon: true,
      key: HistoryKey.SORT_BY_SIGNED,
    },
    {
      label: 'Actions',
      key: '',
    },
  ];

  const toggleStatusColumn = (
    event: BaseSyntheticEvent,
    key: DocumentStatus,
  ) => {
    let statuses = filters.statuses || [];
    if (event?.target?.checked) {
      statuses.push(key);
    } else {
      statuses = statuses.filter((i: string) => i !== key);
    }
    setFilters({
      ...filters,
      page: 0,
      statuses,
    });
  };

  const sortColumn = (key: HistoryKey) => {
    setFilters({
      ...filters,
      [key]: filters[key] === SortType.ASC ? SortType.DESC : SortType.ASC,
    });
  };

  const resetSelectedHandler = () => {
    setSelectedDocuments([]);
    setActiveSelectedAll(false);
  };

  const getHistoryList = () => {
    getHistory({
      ...filters,
      nationalId: session?.claims?.name as string,
    })
      .then(({ data }: { data: HistoryResponse }) => {
        setList(data.documents);
        setDocumentsCount(data.documentsCount);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
    resetSelectedHandler();
  };

  const onDeleteMultipleDocuments = (documentIds: string[]) => {
    deleteMultipleDocuments(documentIds)
      .then(() => {
        getHistoryList();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const onDeleteMultipleDocumentsHandler = () => {
    resetSelectedHandler();
    onDeleteMultipleDocuments(selectedDocuments);
  };

  const onArchiveDocumentHandler = (documentId: string) => {
    archiveDocument(documentId)
      .then(() => {
        getHistoryList();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const onArchiveMultipleDocument = () => {
    archiveMultipleDocuments(selectedDocuments)
      .then(() => {
        getHistoryList();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const onArchiveMultipleDocumentHandler = () => {
    resetSelectedHandler();
    onArchiveMultipleDocument();
  };

  const onSelectDocument = (documentId: string) => {
    let updatedDocuments: string[] = [];
    const documents = [...selectedDocuments];

    if (selectedDocuments.includes(documentId)) {
      const index = selectedDocuments.findIndex((id) => id === documentId);
      documents.splice(index, 1);
      updatedDocuments = documents;
    } else {
      updatedDocuments = [...selectedDocuments, documentId];
    }

    setSelectedDocuments(updatedDocuments);
  };

  const onSelectMultipleDocument = (event: BaseSyntheticEvent) => {
    const updatedDocuments: string[] = event.target.checked
      ? list.map((item) => item.documentId)
      : [];
    setActiveSelectedAll(event.target.checked);
    setSelectedDocuments(updatedDocuments);
  };

  useEffect(() => {
    if (session?.claims?.name) {
      getHistoryList();
    }
  }, [filters, session?.claims?.name]);

  return (
    <div className="row my-5">
      <div className={`${styles.tableContainer} col-12 table-responsive p-3`}>
        <div className="bg-white">
          <table className={`table ${styles.historyTable} m-0 table-hover`}>
            <thead className="bg-white">
              <tr>
                {HEAD.map((item) => {
                  return (
                    <th
                      scope="col"
                      className="cursor-pointer align-middle"
                      key={item.key}
                      onClick={() =>
                        item.showSortIcon && sortColumn(item.key as HistoryKey)
                      }
                    >
                      <div className="d-flex flex-row align-items-center position-relative">
                        <div className="d-inline-block">
                          {item.key === HistoryKey.SELECT && (
                            <input
                              className="form-check-input me-1 mt-0 align-middle custom-checkbox"
                              type="checkbox"
                              checked={activeSelectedAll}
                              onChange={onSelectMultipleDocument}
                            />
                          )}
                          {item.key === HistoryKey.SELECT &&
                            selectedDocuments.length > 0 && (
                              <div
                                className={`${styles.selectedBox} overflow-hidden bg-white position-absolute rounded`}
                              >
                                <ul className="list-unstyled text-center">
                                  <li
                                    onClick={onArchiveMultipleDocumentHandler}
                                    className="py-1 custom-fs-5"
                                  >
                                    Archive
                                  </li>
                                  <li
                                    onClick={onDeleteMultipleDocumentsHandler}
                                    className="py-1 custom-fs-5"
                                  >
                                    Delete
                                  </li>
                                </ul>
                              </div>
                            )}
                        </div>
                        <p
                          className={`${
                            item.key === HistoryKey.SELECT
                              ? styles.labelIcon
                              : ''
                          } d-inline-block m-0 p6-700 w-100 text-description align-middle`}
                        >
                          {item.key === HistoryKey.SELECT ? (
                            <i className="icon-small-arrow-down custom-fs-7 ps-1"></i>
                          ) : (
                            item.label
                          )}
                        </p>
                        {item.showSortIcon &&
                          (filters[item.key] === SortType.ASC ? (
                            <i className="icon-sort custom-fs-5"></i>
                          ) : (
                            <i className="icon-sort-descending custom-fs-5"></i>
                          ))}

                        {item.showFilter && (
                          <div className="dropdown">
                            <button
                              className="btn btn-light p-0"
                              type="button"
                              id="filter"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="icon-filter custom-fs-5"></i>
                            </button>
                            <ul
                              className="dropdown-menu border-0"
                              aria-labelledby="filter"
                            >
                              {[
                                DocumentStatus.SIGNED,
                                DocumentStatus.PENDING,
                                DocumentStatus.CANCELED,
                              ].map((key) => {
                                return (
                                  <li
                                    className="list-group-item d-flex px-2 py-1 align-items-center"
                                    key={key}
                                  >
                                    <input
                                      className="form-check-input m-0"
                                      type="checkbox"
                                      id={key}
                                      onClick={(event) =>
                                        toggleStatusColumn(event, key)
                                      }
                                    />
                                    <label
                                      className="form-check-label p3-400 m-0 ms-2"
                                      htmlFor="signed"
                                    >
                                      {LABEL_MAP.get(key)}
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {list.length ? (
                list.map((item, i: number) => {
                  return (
                    <tr
                      key={i}
                      className="custom-fs-5 text-main cursor-pointer"
                    >
                      <td className="align-middle">
                        <input
                          className="form-check-input custom-checkbox"
                          type="checkbox"
                          checked={selectedDocuments.includes(item.documentId)}
                          onChange={() => onSelectDocument(item.documentId)}
                        />
                      </td>
                      <td
                        className="align-middle"
                        data-bs-toggle="modal"
                        data-bs-target={MODAL_ID}
                        onClick={() => setSelectedItem(item)}
                      >
                        {item.fileName}
                      </td>
                      <td
                        className="align-middle"
                        data-bs-toggle="modal"
                        data-bs-target={MODAL_ID}
                        onClick={() => setSelectedItem(item)}
                      >
                        {DOCUMENT_STATUS_MAP.get(item.status)}
                      </td>
                      <td
                        className="align-middle"
                        data-bs-toggle="modal"
                        data-bs-target={MODAL_ID}
                        onClick={() => setSelectedItem(item)}
                      >
                        {moment(item.created).format(DATE_FORMAT)}
                      </td>
                      <td
                        className="align-middle"
                        data-bs-toggle="modal"
                        data-bs-target={MODAL_ID}
                        onClick={() => setSelectedItem(item)}
                      >
                        {moment(item.signed).format(DATE_FORMAT)}
                      </td>
                      <td className="d-flex flex-row justify-content-center">
                        <button
                          type="button"
                          className="btn btn-light p-0 not-focusable"
                          onClick={() =>
                            downloadFile(item.documentId, item.fileName)
                          }
                        >
                          <Tooltip
                            content={() => (
                              <TooltipContent
                                text={
                                  TOOLTIP_TEXT_MAP.get(
                                    ActionIcons.download,
                                  ) as string
                                }
                                iconClass={ActionIcons.download}
                              />
                            )}
                            direction={TooltipDirection.RIGHT}
                            delay={0}
                          >
                            <i className="icon-download-key text-primary custom-fs-1" />
                          </Tooltip>
                        </button>
                        <button
                          type="button"
                          className="btn btn-light p-0 not-focusable"
                          data-bs-toggle="modal"
                          data-bs-target={MODAL_ID}
                          onClick={() => setSelectedItem(item)}
                        >
                          <Tooltip
                            content={() => (
                              <TooltipContent
                                text={
                                  TOOLTIP_TEXT_MAP.get(
                                    ActionIcons.view,
                                  ) as string
                                }
                                iconClass={ActionIcons.view}
                              />
                            )}
                            direction={TooltipDirection.RIGHT}
                            delay={0}
                          >
                            <i className="icon-eye text-primary custom-fs-1" />
                          </Tooltip>
                        </button>
                        <button
                          disabled={item.status !== DocumentStatus.SIGNED}
                          type="button"
                          className="btn btn-light p-0 not-focusable"
                          onClick={() =>
                            onArchiveDocumentHandler(item.documentId)
                          }
                        >
                          <Tooltip
                            content={() => (
                              <TooltipContent
                                text={
                                  TOOLTIP_TEXT_MAP.get(
                                    ActionIcons.archive,
                                  ) as string
                                }
                                iconClass={ActionIcons.archive}
                              />
                            )}
                            direction={TooltipDirection.RIGHT}
                            delay={0}
                          >
                            <i className="icon-archive text-primary custom-fs-1" />
                          </Tooltip>
                        </button>
                        <button
                          type="button"
                          className="btn p-0 not-focusable"
                          onClick={() =>
                            onDeleteMultipleDocuments([item.documentId])
                          }
                        >
                          <Tooltip
                            content={() => (
                              <TooltipContent
                                text={
                                  TOOLTIP_TEXT_MAP.get(
                                    ActionIcons.delete,
                                  ) as string
                                }
                                iconClass={ActionIcons.delete}
                              />
                            )}
                            direction={TooltipDirection.RIGHT}
                            delay={0}
                          >
                            <i className="icon-delete text-primary custom-fs-1" />
                          </Tooltip>
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <p className="p3-400 d-flex justify-content-center mt-3">
                      No data
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row py-3 px-1">
            <div className="col-6">
              <nav aria-label="Page navigation">
                <ReactPaginate
                  onPageChange={(e) =>
                    setFilters({
                      ...filters,
                      page: e.selected,
                    })
                  }
                  breakLabel="..."
                  forcePage={filters.page}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={1}
                  pageCount={pages}
                  previousLabel="&lt;"
                  nextLabel="&gt;"
                  activeClassName="active"
                  disabledClassName="disabled"
                  containerClassName={`${styles.historyPagination} d-flex list-unstyled flex-row`}
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageClassName="page-item"
                  breakLinkClassName="text-black text-decoration-none"
                  pageLinkClassName="page-link rounded"
                  previousLinkClassName="page-link rounded"
                  nextLinkClassName="page-link rounded"
                />
              </nav>
            </div>
            <div className="col-3 d-flex flex-row justify-content-end">
              <select
                className={`${styles.selectItemsPerPage} ps-1 border-0 p4-400 text-black`}
                value={filters.count}
                onChange={(event) => {
                  const count = Number(event.target.value);
                  setFilters({
                    ...filters,
                    page:
                      filters.page + 1 > Math.ceil(documentsCount / count)
                        ? 0
                        : filters.page,
                    count,
                  });
                }}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
              </select>
              <p className="m-0 align-self-center p5-400 ms-1">
                Items per page
              </p>
            </div>
            <div className="col-3 d-flex justify-content-center">
              <p className="m-0 align-self-center p5-400" key={filters.page}>
                {filters.page + 1} of {pages} pages ({documentsCount} items)
              </p>
            </div>
          </div>
        </div>
      </div>
      <HistoryDetails
        historyItem={selectedItem}
        documentHasChanged={getHistoryList}
      />
    </div>
  );
};
