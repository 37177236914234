import React, { Dispatch, SetStateAction } from 'react';
import ReactCrop, { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';

interface ImageCropProps {
  aspect: number | undefined;
  imgRef: React.RefObject<HTMLImageElement>;
  imgSrc: string;
  scale: number;
  rotate: number;
  crop: Crop | undefined;
  setCropValues: Dispatch<SetStateAction<Crop | PixelCrop | PercentCrop>>;
  onImageLoad: (e: React.SyntheticEvent<HTMLImageElement>) => void;
  display: string;
}

export const ImageCrop = ({
  setCropValues,
  aspect,
  imgRef,
  imgSrc,
  scale,
  rotate,
  crop,
  onImageLoad,
  display,
}: ImageCropProps) => {
  return (
    <div className={`${display} justify-content-center bg-grey-2 py-3 my-4`}>
      <ReactCrop
        style={{
          border: '1px solid black',
          background: 'white',
        }}
        crop={crop}
        onChange={(_, percentCrop) =>
          setCropValues((state: Crop | PixelCrop | PercentCrop) => ({
            ...state,
            crop: percentCrop,
          }))
        }
        onComplete={(c) =>
          setCropValues((state: Crop | PixelCrop | PercentCrop) => ({
            ...state,
            completedCrop: c,
          }))
        }
        aspect={aspect}
      >
        <img
          ref={imgRef}
          alt="ImageCrop me"
          src={imgSrc}
          onLoad={onImageLoad}
          style={{
            transform: `scale(${scale}) rotate(${rotate}deg)`,
          }}
        />
      </ReactCrop>
    </div>
  );
};
