import { useContext } from 'react';

import { AuthContext } from '../../store/AuthContext';

export const UserInfo = () => {
  const { session } = useContext(AuthContext);

  const userInfo = [
    {
      label: 'National ID:',
      value: session?.claims?.name,
    },
    {
      label: 'First Name:',
      value: session?.claims?.firstName,
    },
    {
      label: 'Last Name:',
      value: session?.claims?.lastName,
    },
  ];

  return (
    <div className="m-5">
      {userInfo.map((item, i) => (
        <div className="mb-4" key={i}>
          <p className="m-0 p2-400">{item.label}</p>
          <h6>{item.value}</h6>
        </div>
      ))}
    </div>
  );
};
