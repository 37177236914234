import { FC, memo } from 'react';

import { Buttons } from '../Buttons/Buttons';
import { StatusConfig } from '../../interfaces/status-config';
import { Details, Signature } from '../../interfaces';
import { LABEL_MAP } from '../../constants';
import { DocumentStatus } from '../../enums';
import { toJSONLocal } from '../../utils';

import NoSign from '../../assets/images/icons/no-sign.svg';

import styles from './Status.module.scss';

interface StatusProps {
  config: StatusConfig;
  details: Details;
}

interface DetailsInfoProps {
  item: any;
  customClass?: string;
}

const labels: { [key: string]: string } = {
  signatory: 'Digitally signed by:',
  id: 'E-Signature ID:',
  issuer: 'Issued by:',
  timeStamp: 'Date:',
};

const DetailsInfo: FC<DetailsInfoProps> = memo(({ item, customClass = '' }) => (
  <>
    {Object.keys(item)
      .filter((key) => labels[key] && item[key as keyof Signature])
      .map((key: string) => {
        const value = item[key as keyof Signature];
        return (
          <p className={`${customClass} m-0 p4-400`} key={key}>
            {labels[key]}
            {key === 'timeStamp'
              ? toJSONLocal(new Date(value as string)) + ' UTC+0'
              : value}
          </p>
        );
      })}
  </>
));

export const Status: FC<StatusProps> = memo(({ config, details }) => {
  const { SpinnerComponent } = config;

  return (
    <div className="row status">
      {SpinnerComponent && (
        <div className="col-12">
          <SpinnerComponent />
        </div>
      )}

      {config.img && (
        <div className="col-12">
          <img src={config.img?.url} alt={config.img?.alt} />
        </div>
      )}

      {config.title && (
        <div className="col-12 mt-4 w-75">
          <h4 className="semi-bold text-success">{config.title}</h4>
        </div>
      )}

      {config.subTitleBtn && (
        <div className="col-12 w-75">
          <Buttons buttons={config.subTitleBtn} />
        </div>
      )}

      {config.description && (
        <div className="col-12 mt-3">
          <h6>{config.description}</h6>
        </div>
      )}

      {config.showDetails && details.status !== DocumentStatus.CANCELED && (
        <div className="col-12 mb-1">
          <p className="text-input p3-600 mb-1">Document info:</p>
          <p className="m-0 p3-600">
            E-Signing status: {LABEL_MAP.get(details.status)}
          </p>
          <p className="m-0 p3-600">Filename: {details.fileName}</p>
          {!details.signed && (
            <p className="m-0 p3-600">
              Number of signatures: {details.signatures?.length || 0}
            </p>
          )}
        </div>
      )}

      {config.showDetails && details.status === DocumentStatus.CANCELED && (
        <div className="col-12 mb-1">
          <img
            src={NoSign}
            className="img-fluid"
            alt="No signature"
            width={148}
            height={108}
          />
          <h4 className="h4-600 text-error">No signatures found</h4>
        </div>
      )}

      {details.signatures && details.signatures?.length > 0 && (
        <div className={`${styles.details} col-12`}>
          {details.signatures.map((item: Signature, i) => {
            return (
              <div key={i} className="mt-1">
                <p className="p3-600 text-primary mb-1">
                  E-signature ({i + 1}) details:
                </p>
                <div className="mt-2">
                  <DetailsInfo item={item} />
                </div>
              </div>
            );
          })}
        </div>
      )}

      {config.otp && (
        <div className="col-12 mt-3 w-50 otp">
          <label className="custom-fs-2 mb-2 text-muted">
            One time password:
          </label>
          <div
            className={`bg-grey-1 d-flex justify-content-center align-items-center px-5 py-2 ${styles.newOtp}`}
          >
            <h3 className="mb-0">{config.otp}</h3>
          </div>
        </div>
      )}

      {config.btnDescription && (
        <div className="col-12 mt-4">
          <p className="p2-400 m-0 text-primary">{config.btnDescription}</p>
        </div>
      )}
      {config.btn && (
        <div className="col-12 status-buttons">
          <div className="row buttons-row">
            <div
              className={`col-12 ${
                !config.btnDescription ? 'mt-4' : ''
              } buttons-col`}
            >
              <Buttons buttons={config.btn} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
