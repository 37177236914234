import React, { BaseSyntheticEvent } from 'react';
import UploadDocumentIcon from '../../assets/images/icons/upload-document-icon.svg';
import styles from './DocumentUploadInput.module.scss';

export const DocumentUploadInput = ({
  onChange,
}: {
  onChange: (e: BaseSyntheticEvent) => void;
}) => {
  return (
    <div className="border-dashed d-flex flex-column align-items-center justify-content-center h-100 min-vh-50">
      <img
        src={UploadDocumentIcon}
        width={140}
        height={140}
        alt="Document PDF icon"
      />
      <label htmlFor="file" className="btn btn-outline-primary semi-bold my-5">
        <i className="icon-download-key custom-fs-3 mx-2" />
        Select a file
      </label>
      <input
        type="file"
        id={styles['fileUpload']}
        onChange={onChange}
        accept="application/pdf"
        placeholder="Select a file"
      />
      <h6 className="text-disabled semi-bold">
        or just drop .pdf document here
      </h6>
    </div>
  );
};
