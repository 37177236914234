import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Footer, Header } from '../../components';
import { routes } from '../../models';
import { AuthContext } from '../../store/AuthContext';
import { getFullName } from '../../utils/utils';
import ProfileAvatar from '../../assets/images/icons/profile.svg';

import styles from './Profile.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

export const Profile = ({ children }: LayoutProps) => {
  const location = useLocation();
  const { session } = useContext(AuthContext);
  const menuSettings = [
    {
      link: routes.profileInfo,
      label: 'User info',
      svg: (
        <>
          <svg
            className={
              location.pathname === routes.profileInfo ? styles.hidden : ''
            }
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.34591 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99076 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z"
              fill="#B72913"
            />
            <path
              d="M9.99987 8.33359H9.16654C8.94552 8.33359 8.73356 8.42138 8.57728 8.57767C8.421 8.73395 8.3332 8.94591 8.3332 9.16692C8.3332 9.38793 8.421 9.5999 8.57728 9.75618C8.73356 9.91246 8.94552 10.0003 9.16654 10.0003H9.99987V15.0003C9.99987 15.2213 10.0877 15.4332 10.2439 15.5895C10.4002 15.7458 10.6122 15.8336 10.8332 15.8336C11.0542 15.8336 11.2662 15.7458 11.4225 15.5895C11.5787 15.4332 11.6665 15.2213 11.6665 15.0003V10.0003C11.6665 9.55823 11.4909 9.1343 11.1784 8.82174C10.8658 8.50918 10.4419 8.33359 9.99987 8.33359Z"
              fill="#B72913"
            />
            <path
              d="M10 6.66641C10.6904 6.66641 11.25 6.10676 11.25 5.41641C11.25 4.72605 10.6904 4.16641 10 4.16641C9.30965 4.16641 8.75 4.72605 8.75 5.41641C8.75 6.10676 9.30965 6.66641 10 6.66641Z"
              fill="#B72913"
            />
          </svg>
          <svg
            className={
              location.pathname !== routes.profileInfo ? styles.hidden : ''
            }
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 24C14.3734 24 16.6935 23.2962 18.6668 21.9776C20.6402 20.6591 22.1783 18.7849 23.0866 16.5922C23.9948 14.3995 24.2324 11.9867 23.7694 9.65892C23.3064 7.33115 22.1635 5.19295 20.4853 3.51472C18.8071 1.83649 16.6689 0.693605 14.3411 0.230582C12.0133 -0.232441 9.60051 0.00519943 7.4078 0.913451C5.21509 1.8217 3.34094 3.35977 2.02236 5.33316C0.703788 7.30655 0 9.62663 0 12C0.00344108 15.1815 1.26883 18.2318 3.51852 20.4815C5.76821 22.7312 8.81846 23.9966 12 24ZM12 5.00001C12.2967 5.00001 12.5867 5.08798 12.8334 5.2528C13.08 5.41762 13.2723 5.65189 13.3858 5.92598C13.4994 6.20007 13.5291 6.50167 13.4712 6.79264C13.4133 7.08361 13.2704 7.35089 13.0607 7.56067C12.8509 7.77044 12.5836 7.91331 12.2926 7.97118C12.0017 8.02906 11.7001 7.99936 11.426 7.88582C11.1519 7.77229 10.9176 7.58003 10.7528 7.33336C10.588 7.08669 10.5 6.79668 10.5 6.50001C10.5 6.10218 10.658 5.72065 10.9393 5.43934C11.2206 5.15804 11.6022 5.00001 12 5.00001ZM11 10H12C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V12H11C10.7348 12 10.4804 11.8946 10.2929 11.7071C10.1054 11.5196 10 11.2652 10 11C10 10.7348 10.1054 10.4804 10.2929 10.2929C10.4804 10.1054 10.7348 10 11 10Z"
              fill="#B72913"
            />
          </svg>
        </>
      ),
    },
    {
      link: routes.profileHistory,
      label: 'History',
      svg: (
        <>
          <svg
            className={
              location.pathname === routes.profileHistory ? styles.hidden : ''
            }
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 23.9997H1C0.734784 23.9997 0.48043 23.8943 0.292893 23.7068C0.105357 23.5192 0 23.2649 0 22.9997C0 22.7345 0.105357 22.4801 0.292893 22.2926C0.48043 22.105 0.734784 21.9997 1 21.9997H9C9.26522 21.9997 9.51957 22.105 9.70711 22.2926C9.89464 22.4801 10 22.7345 10 22.9997C10 23.2649 9.89464 23.5192 9.70711 23.7068C9.51957 23.8943 9.26522 23.9997 9 23.9997Z"
              fill="#B72913"
            />
            <path
              d="M7 20.0003H1C0.734784 20.0003 0.48043 19.8949 0.292893 19.7074C0.105357 19.5199 0 19.2655 0 19.0003C0 18.7351 0.105357 18.4807 0.292893 18.2932C0.48043 18.1056 0.734784 18.0003 1 18.0003H7C7.26522 18.0003 7.51957 18.1056 7.70711 18.2932C7.89464 18.4807 8 18.7351 8 19.0003C8 19.2655 7.89464 19.5199 7.70711 19.7074C7.51957 19.8949 7.26522 20.0003 7 20.0003Z"
              fill="#B72913"
            />
            <path
              d="M5 16.0009H1C0.734784 16.0009 0.48043 15.8956 0.292893 15.708C0.105357 15.5205 0 15.2661 0 15.0009C0 14.7357 0.105357 14.4813 0.292893 14.2938C0.48043 14.1063 0.734784 14.0009 1 14.0009H5C5.26522 14.0009 5.51957 14.1063 5.70711 14.2938C5.89464 14.4813 6 14.7357 6 15.0009C6 15.2661 5.89464 15.5205 5.70711 15.708C5.51957 15.8956 5.26522 16.0009 5 16.0009Z"
              fill="#B72913"
            />
            <path
              d="M13.0002 23.9549C12.735 23.9667 12.4759 23.8726 12.28 23.6934C12.0842 23.5143 11.9675 23.2646 11.9557 22.9994C11.9439 22.7342 12.0379 22.4751 12.2171 22.2792C12.3963 22.0833 12.646 21.9667 12.9112 21.9549C14.8036 21.7817 16.6076 21.0729 18.1117 19.9115C19.6158 18.75 20.7579 17.184 21.4041 15.3969C22.0503 13.6098 22.1739 11.6755 21.7604 9.82068C21.3469 7.96584 20.4135 6.26722 19.0694 4.92377C17.7254 3.58032 16.0263 2.64767 14.1713 2.23504C12.3163 1.82241 10.3821 1.9469 8.59526 2.59393C6.80844 3.24096 5.24297 4.38373 4.08222 5.8884C2.92146 7.39307 2.21348 9.19733 2.0412 11.0899C2.01733 11.354 1.8895 11.5979 1.68583 11.7678C1.48217 11.9377 1.21935 12.0197 0.955196 11.9959C0.691041 11.972 0.447187 11.8442 0.277279 11.6405C0.107372 11.4368 0.0253281 11.174 0.0491976 10.9099C0.328086 7.83318 1.7828 4.98257 4.11052 2.95144C6.43824 0.920301 9.45963 -0.134865 12.5457 0.00558262C15.6318 0.14603 18.5449 1.47127 20.6785 3.70544C22.8121 5.93961 24.0019 8.91056 24.0002 11.9999C24.0155 14.9982 22.9016 17.8925 20.8801 20.1069C18.8585 22.3213 16.0775 23.6936 13.0902 23.9509C13.0602 23.9539 13.0292 23.9549 13.0002 23.9549Z"
              fill="#B72913"
            />
            <path
              d="M12.0002 6.00029C11.735 6.00029 11.4806 6.10565 11.2931 6.29319C11.1055 6.48072 11.0002 6.73508 11.0002 7.00029V12.0003C11.0002 12.2655 11.1056 12.5198 11.2932 12.7073L14.2932 15.7073C14.4818 15.8895 14.7344 15.9902 14.9966 15.988C15.2588 15.9857 15.5096 15.8805 15.695 15.6951C15.8804 15.5097 15.9856 15.2589 15.9878 14.9967C15.9901 14.7345 15.8893 14.4819 15.7072 14.2933L13.0002 11.5863V7.00029C13.0002 6.73508 12.8948 6.48072 12.7073 6.29319C12.5197 6.10565 12.2654 6.00029 12.0002 6.00029Z"
              fill="#B72913"
            />
          </svg>
          <svg
            className={
              location.pathname !== routes.profileHistory ? styles.hidden : ''
            }
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33333 19.1661C8.33333 19.3872 8.24554 19.5991 8.08926 19.7554C7.93297 19.9117 7.72101 19.9995 7.5 19.9995H0.833333C0.61232 19.9995 0.400358 19.9117 0.244078 19.7554C0.0877974 19.5991 0 19.3872 0 19.1661C0 18.9451 0.0877974 18.7332 0.244078 18.5769C0.400358 18.4206 0.61232 18.3328 0.833333 18.3328H7.5C7.72101 18.3328 7.93297 18.4206 8.08926 18.5769C8.24554 18.7332 8.33333 18.9451 8.33333 19.1661Z"
              fill="#B72913"
            />
            <path
              d="M0.833333 16.6667H5.83333C6.05435 16.6667 6.26631 16.5789 6.42259 16.4226C6.57887 16.2663 6.66667 16.0544 6.66667 15.8333C6.66667 15.6123 6.57887 15.4004 6.42259 15.2441C6.26631 15.0878 6.05435 15 5.83333 15H0.833333C0.61232 15 0.400358 15.0878 0.244078 15.2441C0.0877974 15.4004 0 15.6123 0 15.8333C0 16.0544 0.0877974 16.2663 0.244078 16.4226C0.400358 16.5789 0.61232 16.6667 0.833333 16.6667Z"
              fill="#B72913"
            />
            <path
              d="M0.833333 13.3339H4.16667C4.38768 13.3339 4.59964 13.2461 4.75592 13.0898C4.9122 12.9335 5 12.7215 5 12.5005C5 12.2795 4.9122 12.0675 4.75592 11.9113C4.59964 11.755 4.38768 11.6672 4.16667 11.6672H0.833333C0.61232 11.6672 0.400358 11.755 0.244078 11.9113C0.0877974 12.0675 0 12.2795 0 12.5005C0 12.7215 0.0877974 12.9335 0.244078 13.0898C0.400358 13.2461 0.61232 13.3339 0.833333 13.3339Z"
              fill="#B72913"
            />
            <path
              d="M10 0C7.34784 0 4.8043 1.05357 2.92894 2.92893C1.05357 4.8043 6.35783e-06 7.34784 6.35783e-06 10C6.35783e-06 10.0508 0.00667302 10.1 0.00750636 10.1508C0.272169 10.0538 0.551475 10.0028 0.83334 10H4.16667C4.57575 9.99861 4.97888 10.098 5.34047 10.2893C5.70205 10.4806 6.01097 10.758 6.23993 11.097C6.4689 11.436 6.61088 11.8262 6.65333 12.2331C6.69578 12.64 6.6374 13.051 6.48334 13.43C6.82454 13.5195 7.14271 13.6809 7.41659 13.9032C7.69046 14.1255 7.91374 14.4037 8.07149 14.7193C8.22925 15.0348 8.31786 15.3803 8.3314 15.7328C8.34494 16.0853 8.2831 16.4366 8.15001 16.7633C8.48327 16.8483 8.79498 17.0024 9.06497 17.2154C9.33496 17.4285 9.55723 17.6958 9.71738 18.0002C9.87754 18.3046 9.97202 18.6392 9.99473 18.9824C10.0174 19.3256 9.96784 19.6697 9.84917 19.9925C9.90001 19.9925 9.94917 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM13.0892 13.0892C12.9329 13.2454 12.721 13.3332 12.5 13.3332C12.279 13.3332 12.0671 13.2454 11.9108 13.0892L9.41084 10.5892C9.25455 10.4329 9.16672 10.221 9.16667 10V5.83333C9.16667 5.61232 9.25447 5.40036 9.41075 5.24408C9.56703 5.0878 9.77899 5 10 5C10.221 5 10.433 5.0878 10.5893 5.24408C10.7455 5.40036 10.8333 5.61232 10.8333 5.83333V9.655L13.0892 11.9108C13.2454 12.0671 13.3332 12.279 13.3332 12.5C13.3332 12.721 13.2454 12.9329 13.0892 13.0892Z"
              fill="#B72913"
            />
          </svg>
        </>
      ),
    },
  ];

  return (
    <>
      <Header />
      <main className="pt-5" id="mainContainer">
        <div className="container d-flex">
          <div className="row w-100">
            <div className="col-md-3 col-sm-4">
              <div className="d-flex flex-column pt-5 mx-3 bg-white h-100">
                <div className="w-100 d-flex flex-column align-items-center mt-3 mb-4">
                  <img src={ProfileAvatar} alt="Avatar" />
                  <p className="fs-4 mt-3 w-50 text-center text-muted">
                    {getFullName(session)}
                  </p>
                </div>
                <div className="px-3">
                  <ul className="nav nav-pills flex-column position-relative">
                    {menuSettings.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            styles.link
                          } btn bg-white border-0 py-2 px-3 rounded overflow-hidden ${
                            item.link === location.pathname
                              ? `${styles.isActive} active`
                              : ''
                          }`}
                        >
                          <Link
                            to={item.link}
                            className="text-decoration-none text-black d-flex align-items-center"
                          >
                            <div
                              className={`${styles.menuIcon} d-flex justify-content-center`}
                            >
                              {item.svg}
                            </div>
                            <p className="ms-2 m-0 p3-400">{item.label}</p>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-8 pt-5">{children}</div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
